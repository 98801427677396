.fill {
  &-primary {
    fill: rgba($iconColor, 1);
  }

  &-secondary {
    fill: rgba($iconColor, 0.5);
  }

  &-bg {
    fill: $backgroundColor;
  }

  &-light {
    fill: rgba($iconColorLight, 1);
  }

  &-error {
    fill: $errorColor;
  }

  &-success {
    fill: $buttonSuccess;
  }
}

.stroke {
  &-primary {
    stroke: rgba($iconColor, 1);
  }

  &-secondary {
    stroke: rgba($iconColor, 0.5);
  }

  &-bg {
    stroke: $backgroundColor;
  }

  &-light {
    stroke: rgba($iconColorLight, 1);
  }

  &-error {
    stroke: $errorColor;
  }

  &-success {
    stroke: $buttonSuccess;
  }
}

button {
  &:disabled {
    mat-icon {
      .fill {
        &-primary {
          fill: rgba($iconColorDisabled, 1);
        }

        &-secondary {
          fill: rgba($iconColorDisabled, 0.5);
        }

        &-bg {
          fill: $backgroundColor;
        }

        &-light {
          fill: rgba($iconColorLightDisabled, 1);
        }

        &-error {
          fill: $iconColorDisabled;
        }

        &-success {
          fill: $iconColorDisabled;
        }
      }

      .stroke {
        &-primary {
          stroke: rgba($iconColorDisabled, 1);
        }

        &-secondary {
          stroke: rgba($iconColorDisabled, 0.5);
        }

        &-bg {
          stroke: $backgroundColor;
        }

        &-light {
          stroke: rgba($iconColorLightDisabled, 1);
        }

        &-error {
          stroke: $iconColorDisabled;
        }

        &-success {
          stroke: $iconColorDisabled;
        }
      }
    }
  }
}

@mixin iconsDisabled {
  .fill {
    &-primary {
      fill: rgba($iconColorDisabled, 1);
    }

    &-secondary {
      fill: rgba($iconColorDisabled, 0.5);
    }

    &-bg {
      fill: $backgroundColor;
    }

    &-light {
      fill: rgba($iconColorLightDisabled, 1);
    }

    &-error {
      fill: $iconColorDisabled;
    }

    &-success {
      fill: $iconColorDisabled;
    }
  }

  .stroke {
    &-primary {
      stroke: rgba($iconColorDisabled, 1);
    }

    &-secondary {
      stroke: rgba($iconColorDisabled, 0.5);
    }

    &-bg {
      stroke: $backgroundColor;
    }

    &-light {
      stroke: rgba($iconColorLightDisabled, 1);
    }

    &-error {
      stroke: $iconColorDisabled;
    }

    &-success {
      stroke: $iconColorDisabled;
    }
  }
}

body {
  .mat-icon {
    &.permission-ico {
      height: 18px;
      width: 18px;
    }
  }
}

.navigation__link--active,
.navigation__link:hover,
.navigation__link:focus,
.group__title--active,
.group__title:focus,
.group__title:hover,
.group .group__title--active,
.group:hover .group__title--active {
  .mat-icon {
    transition: all 0.3s ease-in-out;

    .stroke-white {
      stroke: #fff;
    }

    .fill-white {
      fill: #fff;
    }

    .fill-green {
      fill: #5DA70A;
    }

    *[stroke="#98c6eb"] {
      stroke: #fff;
    }

    *[fill="#98c6eb"] {
      fill: #fff;
    }

    *[fill="#0873c1"] {
      fill: #5DA70A;
    }

    *[stroke="#0873c1"] {
      stroke: #5DA70A;
    }
  }
}

.group:hover .group__title:not(.group__title--active) {
  .mat-icon {
    transition: all 0.3s ease-in-out;

    .stroke-white {
      stroke: #fff;
    }

    .fill-white {
      fill: #fff;
    }

    .fill-green {
      fill: #005491;
    }

    *[stroke="#98c6eb"] {
      stroke: #fff;
    }

    *[fill="#98c6eb"] {
      fill: #fff;
    }

    *[fill="#0873c1"] {
      fill: #005491;
    }

    *[stroke="#0873c1"] {
      stroke: #005491;
    }
  }

}

.group:hover .group__title:not(.group__title--active):hover,
.group:hover .group__title:not(.group__title--active):focus {
  .mat-icon {
    transition: all 0.3s ease-in-out;

    .stroke-white {
      stroke: #98C6EB;
    }

    .fill-white {
      fill: #98C6EB;
    }

    .fill-green {
      fill: #072648;
    }

    *[stroke="#98c6eb"] {
      stroke: #98C6EB;
    }

    *[fill="#98c6eb"] {
      fill: #98C6EB;
    }

    *[fill="#0873c1"] {
      fill: #072648;
    }

    *[stroke="#0873c1"] {
      stroke: #072648;
    }
  }

}

.navigation__link:hover,
.navigation__link:focus,
.group .group__title:focus,
.group .group__title:hover {
  .mat-icon {
    transition: all 0.3s ease-in-out;

    .stroke-white {
      stroke: #98C6EB;
    }

    .fill-white {
      fill: #98C6EB;
    }

    .fill-green {
      fill: #072648;
    }

    *[stroke="#98c6eb"] {
      stroke: #98C6EB;
    }

    *[fill="#98c6eb"] {
      fill: #98C6EB;
    }

    *[fill="#0873c1"] {
      fill: #072648;
    }

    *[stroke="#0873c1"] {
      stroke: #072648;
    }
  }
}

.group:hover .group__list--content a:not(.group__title--active),
.group:focus .group__list--content a:not(.group__title--active) {
  .mat-icon {
    transition: all 0.3s ease-in-out;

    .stroke-white {
      stroke: #98C6EB;
    }

    .fill-white {
      fill: #98C6EB;
    }

    .fill-green {
      fill: #005491;
    }

    *[stroke="#98c6eb"] {
      stroke: #98C6EB;
    }

    *[fill="#98c6eb"] {
      fill: #98C6EB;
    }

    *[fill="#0873c1"] {
      fill: #005491;
    }

    *[stroke="#0873c1"] {
      stroke: #005491;
    }
  }
}

.group .group__list--content a:hover,
.group .group__list--content a:focus {
  .mat-icon {
    transition: all 0.3s ease-in-out;

    .stroke-white {
      stroke: #98C6EB;
    }

    .fill-white {
      fill: #98C6EB;
    }

    .fill-green {
      fill: #072648;
    }

    *[stroke="#98c6eb"] {
      stroke: #98C6EB;
    }

    *[fill="#98c6eb"] {
      fill: #98C6EB;
    }

    *[fill="#0873c1"] {
      fill: #072648;
    }

    *[stroke="#0873c1"] {
      stroke: #072648;
    }
  }
}
