@import 'src/variables';

.color {

  &--primary {
    color: $primaryColor;
  }

  &--red {
    color: #AB2929
  }

  &--green {
    color: #3A8C1D
  }

  &--blue {
    color: #2684C8
  }

}

.bgColor {

  &--primary {
    background-color: $primaryColor;
  }

  &--red {
    background-color: #AB2929
  }

  &--green {
    background-color: #3A8C1D
  }

  &--blue {
    background-color: #2684C8
  }

}
