body {
  .button {
    background-color: $backgroundColor;
    font-size: 0.875rem;
    text-transform: uppercase;
    height: 36px;
    border: none;
    position: relative;
    line-height: 0.875rem;
    border-radius: $buttonBorderRadius;
    color: $buttonDefault;
    padding: 0.75rem 1rem;
    cursor: pointer;
    transition: border-radius 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    text-decoration: none;
    user-select: none;
    font-family: $default-font;


    min-width: 100px;

    // for small button
    &--small {
      font-size: 0.75rem;
      line-height: 0.75rem;
      height: 28px;
      padding: 0.5rem 0.875rem;
    }

    &--type {
      font-size: 0.8125rem;
      line-height: 0.8125rem;
    }

    &--spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    // button border
    &::after {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      border: 1px solid;
      border-radius: $buttonBorderRadius;
      border-color: $buttonDefault;
      transition: all 0.2s ease-in-out;
    }

    // for any hover
    &:hover {
      border-radius: $buttonBorderRadiusHover;

      &::after {
        border-radius: $buttonBorderRadiusHover;
      }
    }

    &--primary {
      background-color: $buttonBackground;
      color: $buttonColor;

      &::after {
        border-color: $buttonBackground;
      }

      &--state {

        // green primary button
        &-success {
          background-color: $buttonSuccess;
          color: $buttonColor;

          &::after {
            border-color: $buttonSuccess;
          }
        }

        // red primary button
        &-error {
          background-color: $buttonError;
          color: $buttonColor;

          &::after {
            border-color: $buttonError;
          }
        }

        // orange primary button
        &-warning {
          background-color: $buttonWarning;
          color: $buttonColor;

          &::after {
            border-color: $buttonWarning;
          }
        }
      }
    }

    &--primary-alt {
      background-color: $buttonDefault;
      color: $buttonColor;

      &::after {
        border-color: $buttonDefault;
      }

      &--state {

        // green primary button
        &-success {
          background-color: $buttonSuccess;
          color: $buttonColor;

          &::after {
            border-color: $buttonSuccess;
          }
        }

        // red primary button
        &-error {
          background-color: $buttonError;
          color: $buttonColor;

          &::after {
            border-color: $buttonError;
          }
        }

        // orange primary button
        &-warning {
          background-color: $buttonWarning;
          color: $buttonColor;

          &::after {
            border-color: $buttonWarning;
          }
        }
      }
    }

    &--secondary {
      color: $buttonBackground;
      background-color: $backgroundColor;

      &::after {
        border-color: $buttonBackground;
      }

      &--state {

        // green secondary button
        &-success {
          background-color: $backgroundColor;
          color: $buttonSuccess;

          &::after {
            border-color: $buttonSuccess;
          }
        }

        // red secondary button
        &-error {
          background-color: $backgroundColor;
          color: $buttonError;

          &::after {
            border-color: $buttonError;
          }
        }

        // orange secondary button
        &-warning {
          background-color: $backgroundColor;
          color: $buttonWarning;

          &::after {
            border-color: $buttonWarning;
          }
        }
      }
    }

    &--transparent {
      background-color: transparent;
      color: $buttonColor;

      &::after {
        border-color: $buttonColor;
      }
    }

    &--ad {
      font-size: 19px;
      text-transform: none;
      color: $buttonColor;
      background-color: $adButton;
      border-radius: $adButtonBorderRadius;

      &::after {
        border-color: $adButtonBorder;
        border-radius: $adButtonBorderRadius;
      }

      &:hover {
        border-radius: $buttonBorderRadius;

        &::after {
          border-radius: $buttonBorderRadius;
        }
      }
    }

    // for any focus
    &:focus {
      outline: unset;
      background-color: $buttonFocus;
      color: $buttonDefault;
      border-radius: $buttonBorderRadius;

      &::after {
        border-color: $buttonHover;
        border-radius: $buttonBorderRadius;
        border-width: 1px;
        z-index: 1;
      }
    }

    // for any disabled button
    &:disabled {
      background-color: $buttonDisabledBackground;
      color: $buttonDisabled;
      cursor: not-allowed;

      &::after {
        border-color: $buttonDisabledBackground;
      }

      &:hover {
        border-radius: $buttonBorderRadius;
        background-color: $buttonDisabledBackground;

        &::after {
          border-color: $buttonDisabledBackground;
          border-radius: $buttonBorderRadius;
        }
      }

      &:focus {
        outline: unset;
        background-color: $buttonDisabledBackground;
        color: $buttonDisabled;
        border-radius: $buttonBorderRadius;

        &::after {
          border-color: $buttonDisabledBackground;
          border-radius: $buttonBorderRadius;
          border-width: 1px;
          z-index: 1;
        }
      }
    }

    &--icon {
      padding: 2px 0.5rem;
      min-width: unset;
      border: 1px solid transparent;
      transition: all 0.2s ease-in-out;

      &:hover {
        box-shadow: inset 0 0 0 1px $focusColor;
        border-color: $focusColor;
        border-radius: $buttonBorderRadius;
      }

      &::after {
        display: none;
      }
    }
  }

  .icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $backgroundColor;
    border-radius: $buttonBorderRadius;
    color: $buttonDefault;
    border: none;
    position: relative;
    width: 24px;
    height: 24px;

    cursor: pointer;


    .mat-icon {
      height: 18px;
      width: 18px;
      font-size: 18px;
      line-height: 18px;
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      border: 1px solid;
      border-radius: $buttonBorderRadius;
      border-color: $buttonDefault;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      border-radius: $buttonBorderRadiusHover;

      &::after {
        border-radius: $buttonBorderRadiusHover;
      }
    }
  }
}

.theme-scania {
  .button {
    display: flex;
    place-items: center;
    place-content: center;
    height: 48px;
    padding: 16px;

    &:disabled {
      background-color: var(--grey-50) !important;
      border-color: transparent !important;
      color: var(--grey-400) !important;

      &::after {
        border-color: transparent !important;
      }
    }

    &--small {
      height: 40px;
      padding: 12px;
    }

    &--primary, &--primary--state-success {
      text-transform: none;
      background-color: var(--blue-600);
      border-color: transparent;
      color: white;
      margin-top: unset !important;

      &:hover {
        background-color: var(--blue-500);
        border-color: transparent;
        color: white;
      }

      &:focus {
        background-color: var(--blue-500);
        border-color: var(--blue-600);
        color: white;
      }

      &::after {
        border-color: var(--blue-800);
      }

      &--state-error {
        background-color: var(--scania-negative);
        border-color: transparent;
        color: white;

        &:hover {
          background-color: var(--red-600);
          border-color: transparent;
          color: white;
        }

        &:focus {
          background-color: var(--red-400);
          border-color: var(--red-700);
          color: white;
        }

        &::after {
          border-color: transparent;
        }
      }
    }


    &--secondary, &--secondary--state-success {
      text-transform: none;
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.48);
      color: black;

      &:hover {
        background-color: var(--blue-500);
        border-color: transparent;
        color: white;
      }

      &:focus {
        background-color: transparent;
        border-color: var(--blue-400);
        color: black;
      }

      &::after {
        border-color: var(--blue-800);
      }

      &--state-error {
        background-color: var(--scania-negative);
        border-color: transparent;
        color: white;

        &:hover {
          background-color: var(--red-600);
          border-color: transparent;
          color: white;
        }

        &:focus {
          background-color: var(--red-400);
          border-color: var(--red-700);
          color: white;
        }

        &::after {
          border-color: transparent;
        }
      }
    }
  }
}
