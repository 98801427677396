// breakpoints
$b-small: 600px;
$b-medium: 768px;
$b-large: 960px;
$b-extra-large: 1200px;

$default-font: var(--default-font, 'Lato', sans-serif);

$primaryColor: var(--primaryColor, #264B76);
$secondaryColor: var(--secondaryColor, #468231);
$primaryBgColor: var(--primaryBgColor, #ffff);
$backgroundColor: var(--backgroundColor, #ffffff);
$footerBackground: var(--footerBackground, #0E2546);
$footerColor: var(--footerColor, #FFF);
$sectionHeaderColor: var(--sectionHeaderColor, #e0e4ef);
$listHeaderBackground: var(--listHeaderBackground, #F2F9FF);
$appBackground: var(--appBackground, #FAFAFA);
$breadcrumbBg: var(--breadcrumbBg, #ebebeb);

$headerLinearPr: var(--headerLinearPr, #0873C1);
$headerLinearSd: var(--headerLinearSd, #264B76);
$headerLinearDark: var(--headerLinearDark, #072648);

$submenuBackground: var(--submenuBackground, #005491);

$borderColor: var(--borderColor, #d0dded);
$highlightColor: var(--highlightColor, #0873c1);
$highlightBackground: var(--highlightBackground, #edf3ff);
$highlightError: var(--highlightError, #eb6a6a);
$errorColor: var(--errorColor, #a71f1f);
$selectedBackground: var(--selectedBackground, rgba(0, 0, 0, 0.05));
$hintColor: var(--hintColor, rgba(0, 0, 0, 0.54));
$hintBackground: var(--hintBackground, #DFF0FE);
$hintBorder: var(--hintBorder, #98C6EB);
$errorBackgroundLight: var(--errorBackgroundLight, #fff6f6);

$inputBorder: var(--inputBorder, #364e6c);
$inputBorderRadius: var(--inputBorderRadius, 4px);

$smallBorderRadius: var(--smallBorderRadius, 4px);

$buttonDefault: var(--buttonDefault, #072648);
$buttonColor: var(--buttonColor, #FFF);
$buttonBackground: var(--buttonBackground, #2E78C1);
$buttonHover: var(--buttonHover, #072648);
$buttonError: var(--buttonError, #A71F1F);
$buttonSuccess: var(--buttonSuccess, #318712);
$buttonWarning: var(--buttonWarning, #C45401);
$buttonBorderRadius: var(--buttonBorderRadius, 4px);
$buttonBorderRadiusHover: var(--buttonBorderRadiusHover, 20px);
$buttonFocus: var(--buttonFocus, #E8EFFD);
$buttonDisabledBackground: var(--buttonDisabledBackground, #f8f8f9);
$buttonDisabled: var(--buttonDisabled, #585e64);
$adButton: var(--adButton, #E37A00);
$adButtonBorder: var(--adButtonBorder, #707070);
$adButtonBorderRadius: var(--adButtonBorderRadius, 20px);

$dataLabelLightBlue: var(--dataLabelLightBlue, #51647C);
$dataLabelLight: var(--dataLabelLight, #6C86A5);

$inputDisabledBackground: var(--inputDisabledBackground, #D6DAE2);

$selectOptionBorder: var(--selectOptionBorder, #D3D9E8);

$toggleBorder: var(--toggleBorder, #0873C1);
$toggleColor: var(--toggleColor, #0873C1);
$toggleHover: var(--toggleHover, #DFF0FE);

$disabledBackground: var(--disabledBackground, #E7EAEF);

$focusColor: var(--focusColor, #0873c1);
$inputPlaceholder: var(--inputPlaceholder, #8b8f93);
$inputBackground: var(--inputBackground, #fff);
$inputBorder: var(--inputBorder, #364e6c);

$iconColor: var(--iconColor, 8, 115, 193);
$iconColorDisabled: var(--iconColorDisabled, 88, 94, 100);
$iconColorLight: var(--iconColorLight, 223, 240, 254);
$iconColorLightDisabled: var(--iconColorLightDisabled, 226, 226, 226);

$donutDisabled: var(--donutDisabled, #828281);

$headingBackground: var(--headingBackground, #f2f9ff);
