@import "src/variables";

.mat-accordion {
  &.app-table {

    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
      background: $highlightBackground;
      border-radius: 5px;
    }

    .mat-expansion-panel {

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      &:hover {
        border: 1px solid $highlightColor;
        box-shadow: 0 0 0 1px $highlightColor;
        transition: all ease-in-out 0.2s;

        .mat-expansion-panel-header {
          transition: all ease-in-out 0.2s;
          background: $highlightBackground;
        }
      }

      &.mat-expanded {

        .mat-expansion-panel-header {
          background: $highlightBackground;
          border-radius: unset;
        }
      }
    }

    .mat-expansion-panel-header {
      padding: 0 16px;
      min-height: 61px;
    }

    .mat-expansion-panel-header-title {
      color: $primaryColor;
      display: flex;
      align-items: center;
    }


    .mat-expansion-panel-spacing {
      margin: 0;
    }

  }
}
