.app-dialog {

  &>* {
    font-family: $default-font;
  }

  &__heading {
    font-weight: 600;
    padding-left: 0;
    font-size: 18px;
    color: $backgroundColor;
  }

  .mat-dialog-container {
    padding: 0;
  }

  .mat-dialog-title {
    background: linear-gradient(45deg, $headerLinearDark, $headerLinearSd);
    color: $backgroundColor;
    padding: .25rem 0 .25rem 1.5rem;
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
  }

  .mat-dialog-content {
    margin: 0;
    padding: 1.5rem;
    min-height: 100px;
    background-repeat: no-repeat;
    background-position-y: calc(100% + 2px);
    background-size: 100%;
    background-image: url("../../assets/images/dialog-background.svg");
  }

  .mat-dialog-actions {
    margin: 0;
    padding: 0 1.5rem 1.5rem;
    background: #F2F2F2;
  }

  .contract-add {
    margin: -1.5rem -1.5rem 1rem -1.5rem;
  }


  .app-panel-title {
    padding-left: 0;

    &__icon {
      height: 40px;
      width: 40px;
      margin-left: 0.5rem;

      .mat-icon {
        width: 27px;
        height: 27px;
      }
    }

    &__title {
      height: 48px;
      display: flex;
      align-items: center;
    }

    &__header {
      color: $primaryColor;
    }
  }

  &.mat-expanded {
    .app-panel-title {
      &__icon {
        height: 50px;
        width: 50px;
        margin-left: 0.5rem;

        .mat-icon {
          width: 35px;
          height: 35px;
        }
      }

      &__title {
        height: 64px;
        display: flex;
        align-items: center;
      }
    }
  }

  .no-background {
    background: none;
  }
}

.app-dialog-p0 {

  .mat-dialog-content {

    padding: 0;
  }
}

.theme-scania {
  .mat-dialog-title {
    background: linear-gradient(45deg, var(--blue-900), var(--blue-800));
  }
}
