@import "src/variables";

body {
  input {
    border: 1px solid $inputBorder;
    color: $primaryColor;
    background: $inputBackground;
    padding: 0.625rem 0.75rem 0.625rem 0.75rem;
    font-size: 0.875rem;
    border-radius: $buttonBorderRadius;
    outline: none;
    height: 36px;
    display: flex;
    align-items: center;
    resize: none;
    font-family: $default-font;
    transition: all 0.2s ease-in-out;

    &::placeholder {
      font-style: italic;
      color: $inputPlaceholder;
    }

    &.ng-invalid.ng-touched::placeholder {
      color: $errorColor;
    }

    &.ng-invalid.ng-touched {
      border: solid 1px $errorColor;
      color: $errorColor;
      box-shadow: inset 0 0 0 1px $errorColor;

      &:not(:disabled):hover {
        box-shadow: inset 0 0 0 1px $focusColor;
        border-color: $focusColor;
      }
    }

    &.ng-invalid.ng-touched + label {
      color: $errorColor;
    }

    &.ng-invalid.ng-touched + label {
      color: $errorColor;
    }

    &:not(:disabled):hover {
      box-shadow: inset 0 0 0 1px $focusColor;
      border-color: $focusColor;
    }

    &:not(:disabled):focus,
    &.ng-touched:not(:disabled):focus {
      outline: none;
      border-color: $focusColor;
      box-shadow: inset 0 0 0 1px $focusColor;
      background: $buttonFocus;
    }

    & + label {
      cursor: pointer;
    }

    &[type=number] {
      appearance: textfield;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }

  label {
    display: block;
    color: $buttonDisabled;
    font-size: 0.75rem;
    margin-bottom: 0.1rem;
    position: relative;

    .error {
      position: absolute;
      top: 2px;
      left: -8px;
      color: $errorColor;
    }
  }

  .form-error {
    color: $errorColor;
    font-size: 0.625rem;
    text-align: left;
    height: 15px;
    padding-top: 2px;
    padding-left: 13px;
  }

  input:disabled {
    border: 0 !important;
    background-color: $inputDisabledBackground;
    color: $buttonDisabled;
    border-color: $inputDisabledBackground;
    cursor: not-allowed;
  }

  textarea {
    border: 1px solid $inputBorder;
    color: $primaryColor;
    background: $inputBackground;
    // padding: 0.625rem 0.75rem 0.625rem 0.75rem;
    font-size: 0.875rem;
    border-radius: $buttonBorderRadius;
    outline: none;
    height: 100%;
    display: flex;
    align-items: center;
    font-family: $default-font;

    &.mat-input-element {
      padding: 10px 12px;
    }

    resize: none;

    &.ng-invalid.ng-touched::placeholder {
      color: $errorColor;
    }

    &.ng-invalid.ng-touched {
      border: solid 1px $errorColor;
      color: $errorColor;
      box-shadow: inset 0 0 0 1px $errorColor;

      &:not(:disabled):hover {
        box-shadow: inset 0 0 0 1px $focusColor;
        border-color: $focusColor;
      }
    }

    &.ng-invalid.ng-touched + label {
      color: $errorColor;
    }

    &:not(:disabled):hover {
      box-shadow: inset 0 0 0 1px $focusColor;
      border-color: $focusColor;
    }

    &:not(:disabled):focus,
    &.ng-touched:not(:disabled):focus {
      outline: none;
      box-shadow: inset 0 0 0 1px $focusColor;
      border-color: $focusColor;
    }

    & + label {
      cursor: pointer;
    }
  }
}

.required {
  font-size: 0.75rem;
  color: $errorColor;
}


// disable autofill changes
body {

  input:-webkit-autofill,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px $inputBackground inset !important;
    transition: none !important;
  }

  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    box-shadow: inset 0 0 0 1px $focusColor,
    0 0 0 30px $inputBackground inset !important;
    transition: none !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    box-shadow: 0 0 0 50px $inputBackground inset;
    -webkit-text-fill-color: $buttonDisabled;
    transition: none !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    font-family: $default-font !important;
    font-size: 0.875rem !important;
    transition: none !important;
  }
}

.theme-scania {
  input {
    box-shadow: none !important;
    border: 1px solid var(--grey-400);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0;
    background: var(--grey-50) !important;
    height: 48px;
  }
}
