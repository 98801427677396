body {

  mat-label {
    font-size: 0.75rem;
    color: $buttonDisabled;
  }

  .mat-form-field-appearance-legacy {
    .mat-form-field-infix {
      padding: 0;
      border: unset;
    }

    .mat-form-field-label {
      transform: none !important;
      color: $primaryColor;
    }
  }

  .mat-form-field {
    display: block;
  }

  .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    position: relative;
    margin: 0;
    padding: 0;

    .mat-error {
      display: flex;
      margin: 0.125rem 0;

      &:not(:first-of-type) {
        padding-left: 0.125rem;
      }
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper,
  .mat-form-field-appearance-legacy .mat-from-field-wrapper {
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 0.5rem;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $inputBorder;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $inputBorder;
  }

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $inputBorder;
  }

  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: transparent;
  }

  .mat-checkbox-frame {
    background: $backgroundColor;
    border-color: $buttonDefault;
    border-width: 1px;
  }

  .mat-checkbox-label {
    color: $buttonDefault;
  }


  .mat-checkbox-checked.mat-accent {
    .mat-checkbox-background {
      background: transparent;
    }

    .mat-checkbox-frame {
      border-color: $buttonBackground;
    }

    .mat-checkbox-checkmark-path {
      stroke: $buttonBackground !important;
    }
  }

  .mat-checkbox.mat-accent {

    &.cdk-focused,
    &:focus {
      .mat-checkbox-frame {
        background: $buttonFocus;
        outline: 1px solid $buttonBackground;
        outline-offset: -2px;
        border-color: $buttonBackground;
      }

      .mat-checkbox-background {
        background: transparent;
      }
    }
  }

  .mat-form-field-type-mat-select .mat-form-field-infix {
    border: unset;
    padding: 0;
  }

  .mat-form-field-type-mat-input .mat-form-field-infix {
    border: unset;
    padding: 0;
  }

  .mat-form-field-type-mat-select {
    .mat-form-field-subscript-wrapper {
      top: unset;
      bottom: 0.25rem;
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 1rem;
  }

  // mat radio button styles
  .mat-radio-button {

    .mat-radio-container,
    .mat-radio-inner-circle {
      height: 16px;
      width: 16px;
    }

    .mat-radio-outer-circle {
      border-width: 1px;
      border-color: $buttonDefault;
      transition: all 0.2s ease-in-out;
      height: 16px;
      width: 16px;
    }

    .mat-ripple-element,
    &.mat-accent.mat-radio-checked .mat-radio-persistent-ripple {
      background: none;
    }

    &.cdk-mouse-focused,
    &.cdk-keyboard-focused {
      transition: all 0.2s ease-in-out;

      .mat-radio-outer-circle {
        border-color: $focusColor;
        border-width: 2px;
      }
    }
  }

  //mat select styles
  .mat-select:not(.paginator-select) {
    border-radius: $buttonBorderRadius;
    padding: 0 1em 0 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 36px;
    border: 1px solid;
    font-size: 0.875rem;
    outline: 0;
    border-color: $inputBorder;
    color: $buttonDefault;
    background: $inputBackground;
    font-family: $default-font;

    &:hover,
    &:focus {
      transition: all 0.2s ease-in-out;
    }

    .mat-select-placeholder {
      display: none;
    }

    .mat-select-trigger {
      height: auto;
    }

    .mat-select-value-text {
      color: $buttonDefault;
    }

    .mat-select-arrow {
      width: 0px;
      height: 0px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 7px solid;
      margin: 0 3px 0 8px;
      color: $buttonDefault;
    }

    &:disabled {
      background-color: $inputDisabledBackground;
      color: $primaryColor;
      border-color: $inputDisabledBackground;
      cursor: not-allowed;
    }

    &.ng-invalid.ng-touched .mat-select-placeholder {
      color: $errorColor;
    }

    .mat-input-element {
      border: 1px solid $inputBorder;
      color: $primaryColor;
      padding: 0.625rem 0.75rem 0.625rem 0.75rem;
      font-size: 0.875rem;
      border-radius: $buttonBorderRadius;
      background: $inputBackground;
      outline: none;
      height: 36px;
      display: flex;
      align-items: center;
      resize: none;
      box-sizing: border-box;
    }

    mat-label {
      display: block;
      font-size: 0.875rem;
      margin-bottom: 0.2em;
      margin-left: 1rem;
      position: relative;
      color: $primaryColor;
    }

    &:hover {
      box-shadow: inset 0 0 0 1px $focusColor;
      border-color: $focusColor;
    }

    &.ng-invalid.ng-touched {
      border: solid 1px $errorColor;
      color: $errorColor;
      box-shadow: inset 0 0 0 1px $errorColor;
    }

    &.ng-invalid.ng-touched+span {
      color: $errorColor;
    }

    &.ng-invalid.ng-touched+span {
      color: $errorColor;
    }

    &:focus,
    &.ng-touched:focus {
      outline: none;
      box-shadow: inset 0 0 0 1px $focusColor;
      border-color: $focusColor;
    }
  }


  .cdk-overlay-pane {
    transform: none !important;

    .mat-select-panel:not([class*=mat-elevation-z]) {
      min-width: calc(100% + 40px) !important;
      margin-top: 33px;
      margin-left: -25px;
      border-radius: $buttonBorderRadius;
      border: 1px solid;
      border-color: $inputBorder;
      color: $primaryColor;
      box-shadow: unset !important;
      background: $inputBackground;

      .mat-active {
        text-decoration: underline;
      }
    }

    .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
      color: $primaryColor;
    }

    .mat-primary .mat-option {
      color: $primaryColor;
    }

    .mat-option:hover:not(.mat-option-disabled),
    .mat-option.mat-active,
    .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
      background: $selectedBackground;
    }

    .mat-option.mat-active {
      outline: 1px dashed $focusColor;
      outline-offset: -1px;
      text-decoration: underline;
    }

    .mat-ripple-element {
      background-color: $selectedBackground;
      transition: none;
    }
  }

  .mat-select {
    &.mat-select-disabled {
      background-color: $inputDisabledBackground;
      color: $primaryColor;
      border-color: $inputDisabledBackground;
      cursor: not-allowed;

      .mat-select-value {
        cursor: not-allowed;
      }

      .mat-select-arrow-wrapper {
        cursor: not-allowed;
      }

      &:focus,
      &.ng-touched:focus {
        outline: none;
        border-radius: $buttonBorderRadius;
      }

      &:hover {
        box-shadow: unset;
      }
    }

    // for change lang select
    &.lang-change {
      min-width: 56px;
      padding: 0 0.25rem;
      border-color: transparent;
      height: 28px;

      .mat-select-value {
        text-align: center;
        max-width: unset;
      }
    }
  }

  // end of mat-selct styles

  // datepicker


  .mat-form-field-type-mat-input {

    .mat-form-field-wrapper {
      padding-bottom: 1rem;
    }

    .mat-form-field-suffix {
      .mat-datepicker-toggle {
        position: absolute;
        right: 10px;
        top: 35px;
        margin-top: -16px;

        .mat-icon-button {
          z-index: 2;
        }

      }
    }
  }

  // end of datepicker

  .mat-form-field-appearance-legacy {
    .mat-form-field-infix {
      padding: 0;
      border: unset;
    }

    .mat-form-field-label {
      transform: none !important;
      color: $primaryColor;
    }
  }

  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-2.02375em) scale(1);
    position: relative;
    margin: 0;

    .mat-placeholder-required {
      color: $errorColor;
    }
  }

  .mat-form-field-subscript-wrapper {
    margin-top: 0.5rem;
    top: calc(100% - 2.35rem);
  }

  .mat-form-field-underline {
    display: none;
  }

  // mat-input styles
  .mat-input-element {
    border: 1px solid $inputBorder;
    color: $primaryColor;
    padding: 0.625rem 0.75rem 0.625rem 0.75rem;
    font-size: 0.875rem;
    border-radius: $buttonBorderRadius;
    background: $inputBackground;
    outline: none;
    height: 36px;
    display: flex;
    align-items: center;
    resize: none;
    font-family: $default-font;
    box-sizing: border-box;
  }

  // end of mat-input styles

  // label fix

  .mat-form-field-appearance-legacy .mat-form-field-infix .mat-form-field-label-wrapper {
    pointer-events: none;
    display: block;
    position: relative;
    padding: 0;
    top: unset;
    overflow: unset;
    margin-bottom: 4px;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
    position: relative;
    display: block;
    top: unset;
    margin: 0;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    cursor: pointer;
    display: flex;
    flex-direction: column-reverse;
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
    width: 100%;
  }

  // end of label fix

  // paginator select

  .select-pages {
    &.mat-form-field-appearance-legacy {

      .mat-select {
        padding: 0 0.5em 0 1rem;

        &-arrow {
          margin: 0 3px 0 3px;
        }
      }

      .mat-form-field-wrapper {
        padding-bottom: 0;
      }

      .mat-form-field-infix {
        width: 190px;
        flex-direction: row-reverse;
        align-items: center;

        .mat-form-field-label-wrapper {
          margin-bottom: 0;
          margin-right: 0.5rem;

          .mat-form-field-label {
            width: 100%;
          }

          mat-label {
            font-size: 0.875rem;
            font-weight: 700;
            color: $primaryColor;
          }
        }
      }
    }
  }

  // end of paginator select
}

.app-panel-danger {


  &.mat-expansion-panel {
    border: 1px solid $errorColor;

    &:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
      background-color: $errorColor;
      color: $backgroundColor;
    }

    &.mat-expanded {
      .mat-expansion-panel-header {

        &:hover,
        &:focus {
          background-color: $errorColor;
        }
      }
    }

    .mat-expansion-panel-header {
      background-color: $errorColor;

      &:hover {
        background-color: $errorColor;
        color: $backgroundColor;
      }
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 0;
      }
    }


    .mat-expansion-panel-header-title {
      h2 {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 1.2rem;
        color: white;
      }
    }
  }
}

.app-panel-bordered {
  &.mat-expansion-panel {
    border: 1px solid $borderColor;

    .mat-expansion-panel-header-title {
      h2 {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 1.2rem;
        color: $primaryColor;
      }
    }
  }
}

body {

  .app-panel-bordered {

    &.mat-expansion-panel:not(.mat-expanded),
    .mat-accordion .mat-expansion-panel:not(.mat-expansion-panel-spacing) {
      border-radius: 5px;
    }
  }
}


.mat-icon {

  &.active {
    color: $secondaryColor;
  }

  &.inactive {
    color: $errorColor;
  }

  &.indeterminate {
    //color: $buttonDisabledBackground;
  }
}

.lang-change-options {
  .mat-option-text {
    text-align: center;
  }
}

// disable dialog bug while autofilling input
body {
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label {
    width: 100%;
  }
}

// datepicker icon
body {
  .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle .mat-icon {
    height: 24px;
    width: 23px;
  }
}

// italic for sort
body {
  .sort {
    .mat-select-value-text {
      font-style: italic;
    }
  }

  .mat-select:not(.paginator-select) {
    &.no-sort {
      .mat-select-value-text {
        color: $buttonDisabled;
      }
    }
  }
}

// indicator display in center
body {
  .mat-expansion-indicator {
    display: grid;

    &::after {
      border-width: 0 1px 1px 0;
      padding: 5px;
      color: $inputBorder;
    }
  }
}

.theme-scania {
  .mat-form-field-wrapper {
    padding-bottom: 18px !important;
  }

  // checkboxes
  .mat-pseudo-checkbox.mat-option-pseudo-checkbox{
    background: white;
    border-color: var(--blue-800);

    &.mat-pseudo-checkbox-checked{
      border-color: var(--blue-800);
      background-color: var(--blue-800);

      &::after{
        color: white;
        border-left: 1px solid currentColor;
        border-bottom: 1px solid currentColor;
      }
    }
  }

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: white;
    border: 1px solid var(--blue-800);

    .mat-checkbox-mixedmark {
      background: var(--blue-800);
    }
  }

  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: transparent;
  }

  .mat-checkbox-frame {
    background: white;
    border-color: var(--blue-800);
    border-width: 1px;
  }

  .mat-checkbox.mat-accent {

    &.cdk-focused,
    &:focus {
      .mat-checkbox-frame {
        background-color: white;
        outline: 1px solid var(--blue-800);
        border-color: var(--blue-800);
      }

      .mat-checkbox-background {
        background: transparent;
      }
    }
  }

  .mat-checkbox-checked.mat-accent {
    .mat-checkbox-background {
      background: transparent;
    }

    .mat-checkbox-frame {
      border-color: var(--blue-800);
      background-color: var(--blue-800);
    }

    .mat-checkbox-checkmark-path {
      stroke: white !important;
    }

    &.cdk-focused,
    &:focus {
      .mat-checkbox-frame {
        background-color: var(--blue-800);
      }
    }
  }

  // mat-input
  mat-form-field {
    .mat-input-element, .cdk-text-field-autofilled {
      box-shadow: none !important;
      border: 1px solid var(--grey-400);
      color: rgba(0, 0, 0, 0.87);
      border-radius: 0;
      background: var(--grey-50) !important;
      height: 48px;

      &:hover {
        box-shadow: none !important;
        background-color: var(--grey-50);
        border: 1px solid var(--grey-600);
      }

      &:focus {
        box-shadow: none !important;
        background-color: var(--grey-50) !important;
        border: 1px solid var(--blue-400);
        color: var(--grey-868);
      }

      &:disabled {
        color: var(--grey-400);
        border: 1px solid transparent;
      }

      &.ng-invalid {
        color: var(--grey-868) !important;
      }
    }

    .mat-form-field-label {
      color: var(--grey-958)
    }
  }

  //mat select styles
  .mat-select:not(.paginator-select) {
    height: 48px;
    box-shadow: none !important;
    border: 1px solid var(--grey-400);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0;
    background: var(--grey-50);

    &:hover {
      background-color: var(--grey-50);
      border: 1px solid var(--grey-600);
    }

    &:focus {
      background-color: var(--grey-50) !important;
      border: 1px solid var(--blue-400);
      color: var(--grey-868);
    }

    &:disabled {
      color: var(--grey-400);
      border: 1px solid transparent;
    }
  }

  // multiple select
  app-multiple-select{
    .mat-select.has-select-count {
      .mat-select-arrow-wrapper{
        &::before{
          height: 48px;
          background-color: var(--blue-800);
          top: -16px;
        }
      }
    }
  }
}

