.filters {
  &-action {
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px);

    @media screen and (max-width: $b-small - 1px) {
      grid-template-columns: repeat(auto-fill, 55px);
    }

    &.sticky-actions {
      grid-template-columns: repeat(auto-fill, 55px);

      mat-icon {
        height: 24px;
      }

      .filters-button {
        grid-template-rows: auto;
      }
    }
  }

  &-button {
    font-family: $default-font;
    margin: 0.25rem;
    padding: 0.5rem;
    // border: 1px solid $borderColor;
    border: none;
    background: $backgroundColor;
    border-radius: 4px;
    display: grid;
    justify-content: center;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    position: relative;
    grid-template-rows: 45px 25px;
    transition: all ease-in-out 0.2s;
    user-select: none;
    // outline: 1px solid transparent;
    // outline-offset: -2px;

    &::after {
      content: "";
      position: absolute;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      border: 1px solid $borderColor;
      border-radius: 4px;
      transition: border-color ease-in-out 0.2s;
    }

    @media screen and (max-width: $b-small - 1px) {
      grid-template-rows: 30px;
    }

    mat-icon {
      margin: 0 auto;
      width: auto;
      height: 30px;

      &[svgIcon="status-change"] {
        width: 55px;
      }

      @media screen and (max-width: $b-small - 1px) {
        height: 30px;
      }
    }

    span {
      text-align: center;
      font-size: 11px;
      line-height: 1.2;
      align-self: center;
      color: $buttonDefault;
      font-family: 'Roboto', sans-serif;

      @media screen and (max-width: $b-small - 1px) {
        display: none;
      }
    }

    &:hover {
      // border-color: $focusColor;
      // outline: 1px solid $focusColor;

      &::after {
        border: 2px solid $focusColor;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
      }
    }

    &:disabled, &.disabled {
      cursor: not-allowed;
      background: $buttonDisabledBackground;
      color: $buttonDisabled;
      border-color: $highlightBackground;
    }

    &.active {
      background: $highlightBackground;
    }

    &:focus {
      outline: unset;
      background-color: $buttonFocus;
      color: $buttonHover;
      border-color: $buttonHover;
      border-radius: $buttonBorderRadius;
    }
  }

  &-more {
    display: grid;
    grid-template-columns: repeat(auto-fill, 90px);
    width: 280px;
    padding: 0 0.25rem;

    .filters {
      &-button {
        grid-template-rows: 35px 30px;

        mat-icon {
          height: 30px;
        }
      }
    }

    @media screen and (max-width: $b-small - 1px) {
      grid-template-columns: repeat(auto-fill, 55px);
    }

    &.sticky-actions {
      grid-template-columns: repeat(auto-fill, 55px);

      mat-icon {
        height: 24px;
      }

      .filters-button {
        grid-template-rows: auto;
      }
    }
  }
}

body {
  .filters-menu {
    background: $headingBackground;
    box-shadow: none;
    border: 1px solid $borderColor;
    border-radius: 0 4px 4px 4px;
    overflow: hidden;

    &::before {
      content: "";
      width: 91px;
      height: 1px;
      top: 0;
      position: absolute;
      left: 0;
      background: $headingBackground;
      display: block;
    }
  }

  .filters-button {
    &[aria-expanded="true"] {
      background: $headingBackground;

      &::after {
        border-radius: 4px 4px 0 0;
        border-bottom-color: transparent;
      }
    }
  }
}
