@use '@angular/material' as mat;
@import 'src/variables';
@import 'global';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import '@angular/material/theming';
@import "components/tableFilter";
@import "components/table";
@import "components/material";
@import "components/buttons";
@import "components/inputs";
@import "components/icons";
@import "components/toggle";
@import "components/dialogs";
@import "components/expansion-panel";
@import "typography";
@import "colors";
@import "utils/spacing";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  overflow-y: scroll;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
}

@include mat.core();

$candy-app-primary: mat.define-palette(mat.$blue-palette);
$candy-app-accent: mat.define-palette(mat.$pink-palette);

$candy-app-warn: mat.define-palette(mat.$red-palette);

$candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

@include mat.all-component-themes($candy-app-theme);
