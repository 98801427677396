@import 'src/variables';

// set default font
p,
h1,
h2,
h3,
h4,
h5,
span,
button,
input,
dt,
dd {
  font-family: $default-font;
}

body {
  font-family: $default-font;
  font-size: 16px;

  &>* {
    font-size: 0.875rem;
  }
}

h1,
.text--h1 {
  color: $primaryColor;
  font-size: 1.75rem; // 28px
}

h2,
.text--h2 {
  color: $primaryColor;
  font-size: 1.5rem;
  font-weight: 400;
}

h3,
.text--h3 {
  font-size: 1.125rem; // 18px
}

h4,
.text--h4 {
  font-size: 0.875rem; // 14px
}

h5,
.text--small {
  font-size: 0.75rem // 12px
}

.text--xs {
  font-size: 0.625rem; // 10px
}

.text--primary {
  color: $primaryColor
}

.text--bold {
  font-weight: 600;
}
