.toggle {
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid $buttonBackground;
  background-color: $backgroundColor;
  font-family: $default-font;

  ul {
    list-style-type: none;
    margin: unset;
    padding: unset;

    li {
      display: inline-block;
    }
  }

  &__button {
    color: $toggleColor;
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    flex: 1;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    position: relative;
    font-family: $default-font;
    transition: all 0.2s ease-in-out;

    &::after {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: calc(100% - 2px);
      border-radius: $buttonBorderRadius;
      transition: all 0.2s ease-in-out;
      border-left: none;
      border-right: none;
    }

    &--active {

      &::after {
        border-bottom: 5px solid $buttonBackground;
      }
    }

    &:focus {
      outline: unset;
      background-color: $buttonFocus;
      color: $buttonHover;
      border-radius: $buttonBorderRadius;
      transition: all 0.2s ease-in-out;

      &::after {
        border-color: $buttonHover;
        border-radius: $buttonBorderRadius;
        border-width: 1px;
        border-style: solid;
        z-index: 1;
        transition: all 0.2s ease-in-out;
      }
    }

  }

  &.wider {
    flex-wrap: wrap;

    .toggle__button {
      flex: 1 50%;
    }
  }
}

@media screen and (min-width: $b-small) {
  .toggle {
    display: block;
    background-color: unset;
    border-bottom: unset;

    &__button {
      height: 36px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: $buttonColor;
      font-size: 0.875rem;
      height: 36px;
      line-height: 0.875rem;
      padding: 0.75rem 1rem;
      border-radius: 0;

      &::after {
        border-radius: 0;
        border-top: 1px solid $toggleBorder;
        border-bottom: 1px solid $toggleBorder;
        transition: all 0.2s ease-in-out;
      }

      &--active {
        color: $backgroundColor;
        background-color: $buttonBackground;

        &::after {
          border-color: $buttonBackground;
        }
      }

      &:first-of-type {
        border-radius: $buttonBorderRadius 0 0 $buttonBorderRadius;

        &::after {
          border-left: 1px solid $toggleBorder;
          border-radius: $buttonBorderRadius 0 0 $buttonBorderRadius;
        }
      }

      &:last-of-type {
        border-radius: 0 $buttonBorderRadius $buttonBorderRadius 0;

        &::after {
          border-right: 1px solid $toggleBorder;
          border-radius: 0 $buttonBorderRadius $buttonBorderRadius 0;
        }
      }

      &:hover {
        background-color: $toggleHover;
        color: $toggleColor;
      }

      &:focus {
        outline: unset;
        background-color: $buttonFocus;
        color: $buttonHover;
        border-radius: $buttonBorderRadius;

        &::after {
          border: 1px solid $buttonHover;
          border-radius: $buttonBorderRadius;
          border-width: 1px;
          z-index: 1;
          left: -1px;
          transition: border-color, border, border-radius 0.2s ease-in-out;
        }
      }
    }

    &.wider {
      flex-wrap: nowrap;

      .toggle__button {
        padding: 0 0.65rem;
        flex: unset;
      }
    }
  }
}

@media (min-width: $b-medium) {
  .toggle {
    &.wider {
      .toggle__button {
        padding: 0 1rem;
      }
    }
  }
}

.theme-scania {
  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: var(--scania-positive) !important;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: white;
  }

  .toggle__button {
    background-color: var(--grey-50);
    color: rgba(0, 0, 0, 0.87);
    text-transform: none;

    &::after {
      border-radius: 0;
    }

    &:hover:not(&--active) {
      background-color: var(--grey-100);
    }

    &:focus {
      outline: unset;
      background-color: var(--grey-200);
      color: rgba(0, 0, 0, 0.87);
      border-radius: 0;

      &::after {
        border-width: 0;
        border-bottom: 5px solid var(--blue-800);
      }
    }

    &--active {
      background-color: var(--grey-100);

      &::after {
        border-bottom: 5px solid var(--blue-800);
      }
    }
  }

  @media screen and (min-width: $b-small) {
    .toggle__button {
      background-color: var(--grey-50);
      border: 1px solid var(--blue-800);
      border-radius: 0;
      color: rgba(0, 0, 0, 0.87);
      text-transform: none;

      &::after {
        border: none;
      }

      &--active {
        color: white;
        background-color: var(--blue-800);
      }

      &:focus {
        background-color: var(--blue-800);
        border: 1px solid var(--blue-800);
        color: white;

        &::after {
          border: none;
        }
      }
    }
  }
}
