table {
  width: 100%;
}


body {
  .mat-table {
    background: unset;
    padding: 0 1.5rem 1.5rem 1.5rem;

    .mat-row {
      background: $backgroundColor;

      .mat-cell {

        &.mat-column-select {
          padding-left: 8px;
        }

        //&:first-of-type {
        //  padding-left: 16px;
        //}
        //
        &:last-of-type {
          padding-right: 8px;
        }

        &:not(:first-child):not(:last-child) {
          margin: 0 5px;
        }
      }
    }

    &.table-w-heading {
      padding: 0;
      border: 1px solid $borderColor;
      border-radius: 4px;

      .mat-header-row {
        border-bottom: 1px solid $borderColor;
        background: #EDF0F7;
        min-height: 45px;

        .mat-header-cell {
          font-size: 14px;
          font-weight: 700;
          color: $primaryColor;
        }
      }

      .mat-row {
        margin: 0;
        padding: 0 5px;
        border: unset;

        &:hover {
          box-shadow: unset;
          background: unset;

          &:nth-child(odd) {
            background: #F7F7FA;
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid $borderColor;
        }

        &:nth-child(odd) {
          background: #F7F7FA;
        }

        .mat-cell {
          color: $primaryColor;
        }
      }
    }

  }
}


.sort {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 1rem;
  min-width: 290px;

  .mat-select {
    min-width: 210px;
    max-width: 250px;
    border: 1px solid $inputBorder;
    border-radius: $inputBorderRadius;
    padding: 5px;
  }

  &__label {
    color: $primaryColor;
    font-size: 13px;
    font-weight: 700;
    margin-right: 0.5rem;
  }
}

.mat-table {
  .mat-row {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 5px;
    margin: 10px 0;
    transition: 0.2s ease-in-out all;
    border-color: $selectOptionBorder;

    &.warn {
      background: #FEF6F6;
      border-color: #D07876;

      &:hover {
        background: $highlightBackground;
        border: 1px solid $highlightColor;
      }
    }


    &.new {
      border: 1px solid #B2CCA4;
      background: #FCFFF5;
    }

    &:hover {
      border: 1px solid $highlightColor;
      background: $highlightBackground;
      box-shadow: inset 0 0 0 1px $highlightColor;
    }

    &.selected {
      border-color: $inputBorder;
      background: $highlightBackground;

      &:hover {
        border: 1px solid $highlightColor;
        background: $highlightBackground;
        box-shadow: inset 0 0 0 1px $highlightColor;
      }
    }
  }

  .mat-cell {
    &::after {
      content: "";
      flex: auto;
    }
  }

  @media screen and (max-width: $b-large - 1px) {
    .mat-row {
      display: flex;
      flex: 1 0 100%;
      flex-wrap: wrap;
    }

    .mat-cell {
      &:nth-child(2) {
        order: 2;
      }

      &:nth-child(3) {
        order: 3;
      }

      &:not(.details-cell):not(.checkbox-cell):not(:nth-child(2)):not(:nth-child(3)) {
        &:nth-child(even) {
          padding-left: 50px;
        }

        &:nth-child(odd) {
          padding-right: 50px;
        }
      }

      @for $i from 4 through 10 {
        &:not(.details-cell):not(.checkbox-cell) {
          &:nth-child(#{$i}) {
            order: $i + 1;
          }
        }
      }

      &.checkbox-cell {
        order: 1;
        max-width: 50px;
      }

      &.details-cell {
        order: 4;
        max-width: 50px;
      }
    }
  }

  @media screen and (max-width: $b-small - 1px) {
    .mat-cell {
      &:nth-child(2) {
        order: 2;
      }

      &:not(.details-cell):not(.checkbox-cell):not(:nth-child(2)) {
        padding: 0 50px;
      }

      @for $i from 3 through 10 {
        &:not(.details-cell):not(.checkbox-cell) {
          &:nth-child(#{$i}) {
            order: $i + 1;
          }
        }
      }

      &.checkbox-cell {
        order: 1;
        max-width: 50px;
      }

      &.details-cell {
        order: 3;
        max-width: 50px;
      }
    }
  }
}

.table {
  background: #fff;
  border-radius: 4px;
  border: 1px solid $borderColor;
  margin-bottom: 1rem;

  &__content {

    padding: 0 1.5rem 1.5rem 1.5rem;
  }


  &__document-icon {
    margin-right: .5rem;


    &.mat-icon {
      height: 28px;
      min-height: 28px;
      width: 28px;
      min-width: 28px;
    }
  }

  &__header {
    padding: 0.75rem 2.4rem;

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .mat-checkbox-label {
      color: $buttonDefault;
    }

    &.checked {
      background: #1E5491;
      position: relative;

      .mat-checkbox-label {
        color: $backgroundColor;
      }

      .table__checked {
        color: white;
      }
    }
  }

  &__checked {
    color: $buttonDisabled;
    font-size: 0.75rem;
    margin-bottom: 3px;
  }

}

.checkbox-cell {
  position: relative;

  .mat-checkbox-inner-container {
    z-index: 1;
  }

  .checkbox-user-ico {
    position: absolute;
    top: -8px;
    left: -18px;
  }

  .mat-checkbox {
    padding-top: 10px;
  }
}

.status {
  font-weight: 700;

  &--signed,
  &--ready,
  &--completed,
  &--positive {
    color: #3A8C1D
  }

  &--draft,
  &--active,
  &--pending,
  &--new {
    color: #2684C8
  }

  &--unsigned,
  &--warn,
  &--reject,
  &--inactive {
    color: #AB2929
  }

}

.empty-list-container {
  padding: 3.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $b-medium) {
    padding: 20px 0;
  }
}

.select-filters {

  .button--small,
  .button--type {
    text-transform: none;
    color: $buttonDefault;

    &::after {
      border: 1px solid $selectOptionBorder;
    }

    // &:hover,
    // &:focus {
    //   border: 1px solid $selectOptionBorder;
    // }

    &.filter-active {
      background: $highlightBackground;
      text-shadow: 0 0 $buttonDefault;
    }
  }
}

.theme-scania {
  .table {
    &__header {
      &.checked {
        background: var(--blue-800);
      }
    }
  }
}

.turnover-container {
  width: 100%;
  overflow: auto;

  .turnover-table {
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;
    border: 1px solid #e5e9f1;
    border-radius: 8px;

    thead {
      background-color: #f0f3f8;
    }

    th {
      padding: 0.4rem;
      text-align: left;
      font-weight: bold;
      color: #476783;
      border-bottom: 2px solid #e5e9f1;
      word-break: break-word;
      align-self: end;
    }

    td {
      padding: 0.5rem 0.4rem;
      border-bottom: 1px solid #e5e9f1;
    }

    td, th {
      min-width: 11rem;
    }

    tr td:nth-child(1), tr th:nth-child(1) {
      min-width: 4rem;
    }

    tbody tr:nth-child(even) {
      background-color: #f9f9fb;
    }

    tbody tr:hover {
      background-color: #F2F2F2;
    }

    &.turnover-vs-network-average {
      tbody tr:nth-child(1), tbody tr:nth-child(3), tbody tr:nth-child(5), tbody tr:nth-child(7) {
        background-color: #dee3ef;
      }
      tbody tr:nth-child(2), tbody tr:nth-child(4), tbody tr:nth-child(6), tbody tr:nth-child(8) {
        background-color: #e9f1ff;
      }
      tbody tr:nth-child(8) {
        border-bottom: 2px solid #e5e9f1;
      }
    }
  }
}

