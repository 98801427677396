@import "src/variables";

.backdrop-blur {
  background-color: #bbbbbbf2;
  backdrop-filter: blur(4px);
}

body {
  background-color: $appBackground;

  &.videochat-view {
    background-color: #242831;

    main.content {
      max-width: 100%;
      padding-bottom: 0;
    }

    .sticky-section {
      display: none;
    }

    .breadcrumb-container {
      display: none;
    }
  }
}

.content {
  max-width: 90%;
  margin: 0 auto;

  @media screen and (max-width: $b-medium) {
    max-width: 100%;
  }
}

main {
  &.content {
    min-height: calc(100vh - 93px - 155px);
    padding-bottom: 1.5rem;
    max-width: calc(90% - 90px);

    @media screen and (max-width: 1279px) {
      max-width: calc(100% - 90px);
    }

    @media screen and (max-width: $b-medium) {
      max-width: calc(100% - 20px);
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.page {
  &__title {
    padding: 14px 0;
    text-transform: uppercase;
    color: $primaryColor;
    font-weight: 700;
  }

  &__switch {
    margin-bottom: 1rem;

    @media screen and (max-width: $b-medium) {
      padding: 0 1rem;
    }
  }

  &__actions {
    max-width: 100%;
    margin-bottom: 1rem;

    @media screen and (max-width: $b-medium) {
      padding: 0 1rem;
    }
  }

  &__content {
    max-width: 100%;
    margin: 1rem 0;

    @media screen and (max-width: $b-medium) {
      padding: 0;
    }
  }
}

.section {
  &__wrapper {
    padding: 1rem;
  }

  &__header {
    padding: 0.75rem 1rem;
    min-height: 30px;
    background: $sectionHeaderColor;
  }
}

.empty-space {
  border-color: $borderColor;
  height: 1px;
  border: none;
  border-top: 1px solid $borderColor;
  padding-top: 1rem;
  margin-top: 1rem;
}

.data {
  display: flex;
  flex-direction: column;
  font-family: $default-font;
  overflow: hidden;

  &__label {
    font-size: 0.75rem;
    color: #7088A5;
    margin-bottom: 4px;
  }

  &__item {
    overflow: hidden;
    width: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $primaryColor;
    font-weight: 700;

    &.active {
      color: green;
    }

    &.disabled {
      color: $errorColor;
    }
  }
}

.block-end {
  display: block;
  text-align: end;
}

.app-primary {
  color: $buttonBackground;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.active-filters {
  list-style: none;
  padding-bottom: 0.25rem;

  &__list {
    list-style: none;
    padding: 0;

    li {
      &:first-of-type {
        app-chip {
          .app-chip {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.page__timer {
  margin-top: 1rem;
  min-height: 36px;
}

@media screen and (max-width: 600px) {
  .page__timer {
    min-height: auto;
  }
}

.field {
  &__label {
    font-size: 0.75rem;
    color: grey;
    margin-bottom: .3rem;
  }
}

.hidden {
  visibility: hidden !important;
}


@media screen and (max-width: $b-medium) {
  .filters{

    mat-form-field{
      margin-right: 0 !important;
      width: 100%;
      min-width: auto !important;
    }
  }

  body .cdk-overlay-pane .mat-select-panel:not([class*=mat-elevation-z]){
    margin-left: -20px !important;
  }

  .mat-calendar-header{
    padding: 0 !important;
  }

  .mat-calendar-controls{
    margin: 5px 0px !important;
  }
  
  .mat-datepicker-content .mat-calendar{
    width: calc(100vw - 10px) !important;
    height: auto !important;
    min-height: 350px;
  }

  .sort{
    width: 100%;
    display: flex;
    flex-direction: column;

    .sort__label{
      width: 100%;
    }

    .mat-select{
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  .paginator{
    flex-direction: row !important;
    flex-wrap: wrap;

    .paginator__item:first-child{
      width:100%;
    }

    .paginator__item mat-label{
      font-size: 12px !important;
    }
  }

  article{

    .page__switch.toggle{
      overflow: auto;
    }
  }

  app-contact-card{
    max-width: calc(100vw - 20px);
  }
  app-contact-form{
    max-width: calc(100vw - 20px);

    .forma_kontaktu{
      grid-template-columns: 1fr !important;
    
      mat-radio-group{
        grid-template-columns: 1fr !important;
      }
    }
  }

  .search2 > form > div:first-child{
    margin-bottom: 20px;
  }

  .serwis-desk-lista-box div{
    max-width: 100% !important;
  }

  .bouncing-loader{
    width: auto !important;
  }


  app-dashboard-documents{

    .dashboard-table{
      padding: 5px;
      max-width: calc(100vw - 20px);

      .data__item{
        text-overflow: clip;
        white-space: pre-wrap;
      }
    }
  }

  app-dashboard-settlements{

    .dashboard-table{
      padding: 5px;
      max-width: calc(100vw - 20px);
    }
  }

  app-dashboard-messages{

    .dashboard-table{
      padding: 5px;
      max-width: calc(100vw - 20px);
    
      .mat-row{
        flex-wrap: wrap;

        .cdk-column-subject{
          flex-basis: 100%  !important;
          padding: 0px 16px !important;

        }

        .dashboard-messages__date-cell{
          min-width: 100px;
        }
      }
    }
  }

  .mat-table .mat-cell:not(.details-cell):not(.checkbox-cell):not(:nth-child(2)):not(:nth-child(3)):nth-child(2n){
    padding-left: 0 !important;
  }
  .mat-table .mat-cell:not(.details-cell):not(.checkbox-cell):not(:nth-child(2)){
    padding: 0 !important;
  }
  .mat-table .mat-cell.details-cell{
    order:1 !important;
  }

  app-empty-list-container {

    .mat-table{
       padding: 5px !important;
   
       .data__item{
        white-space: pre-wrap;
       }
    }

  }

  .app-panel-bordered.mat-expansion-panel .mat-expansion-panel-header-title h2{
    font-size: 12px;
  }
  .mat-expansion-panel-header {
    height: auto;
    min-height: 48px;
  }


  .card__header{
    padding:5px; 
    
    h2{
      font-size: 18px;
    }
  }

  .card__header--content{
    padding: 5px !important;

    .data__item{
      font-size: 14px;
    }
  }

  .card__content{
    padding:5px !important;

    .data-box__content{
      padding: 5px;
    }

    mat-expansion-panel-header{
      padding: 0px 10px;
      height: auto !important;
    }

    .mat-expansion-panel-body{
      padding: 0 5px 5px;
    }

    .regulation.ng-star-inserted > div {
      width: 100%;
      flex: 100% !important;
      max-width: 100% !important;
    }

    .regulation__item:nth-child(7) {
      text-align: start !important;
    }
  }
  .app-panel-bordered.mat-expansion-panel .mat-expansion-panel-header-title h2{
    font-size: 12px !important;
  }

  .card__content{

    .data-box__content{
      padding: 10px !important;
      max-width: calc(100vw - 32px);

      dl{
        display: flex !important;
        flex-direction: column;
        gap: 10px !important;
      }

      .data-box__label{

        > div{
          flex-direction: column !important;
          align-items: flex-start !important;
          margin-top: 5px !important;
        }
      }
    }
  }

  app-settlements-donut-chart{

    .mat-expansion-panel-body > div{
      display: flex;
      flex-direction: column !important;

      > div{
        margin-bottom: 10px;
      }
    }
  }

  .mat-expansion-panel-body{
    padding: 0 5px 15px !important;
  }

  app-edit-notification-emails-dialog{

    .mat-dialog-content{
      padding: 5px;
      max-height: 100% !important;

      .box-mail-org{
        display: flex;
        flex-direction: column !important;
        width:100%;
      
        > mat-form-field{
          max-width: 100% !important;
          width:100%;
          margin-right:0 !important;
        }
      }
    }
  }
}


.mat-calendar.mat-calendar__header {
  padding: 20px 20px 10px 20px;
  font-weight: 700;
}